<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<style scoped>

 .sticky-headers {
  position: sticky;
  top: 100px;
  z-index: 1;
  background-color: white;
}
.table-container--visible .sticky-headers-1 {
  position: sticky;
  padding:6px 0px ;
  top: 100px;
  z-index: 3;
  background-color: white;
}
.table-container--visible .sticky-headers-2 {
  position: sticky;
  top: 135px;
  padding:6px 0px ;
  z-index: 2;
  background-color: white;
}
.table-container--visible .sticky-headers-3 {
  position: sticky;
  top: 150px;
  padding:2px 0px ;
  z-index: 1;
  background-color: white;
}
.table-container--visible  .sticktyyyyy {
  position: sticky;
  padding:20px 0px  !important;
  top: 80px;
  background-color: white;
}
 .sticyyyyyyy-btm {
  position: sticky;
  padding:0px 0px ;
  z-index: 10;
  bottom: 30px;
  background-color: skyblue;
}
  .sticktyyyyy .sticky-pad-big{
  padding: 26px 0px;
}
 .sticktyyyyy .sticky-pad-sm{
  padding: 26px 0px;
}
/* .table-container--visible .sticky-headers-1 {
  position: sticky;
  top: 120px;
  z-index: 1;
  background-color: white;
}
.table-container--visible .sticky-headers-2 {
  position: sticky;
  top: 138px;
  z-index: 1;
  background-color: white;
} */
</style>

<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="row" v-if="1">
        <div class="col-md-4">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search by enrollment no or tcn or name"
              aria-describedby="basic-addon2"
              v-model="search_text"
              @keypress.enter="searchByTCN()"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-info"
                type="button"
                @click="searchByTCN()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed">
        <adv-search
          @search="advSearch"
          :is_date_range="true"
          :is_service="true"
          :is_status="false"
          :is_location_enrollment="true"
          :is_location="currentUser.u.is_service_provider"
          :reportData="this.excelreports"
          :titleworksheet="this.titleworksheet"
          :is_default_tramist_status="'200'"
          :json_fields="this.json_fields"
          :header="this.header"
          :report_name="'service_report.xls'"
        />
      </div>
      <div
        class="row"
        v-if="reports.length > 0 && !getLoadingData"
      >
          <div :class="{ 'table-container sticky-header-up col-md-12': true, 'table-container--visible': isTableVisible }">
          <div v-for="(report,i) in reports" :key="i" class="pt-2 pb-4 " >
            <div  :class="search.location !== 'all'  ? '  sticky-pad-big' : '  sticky-pad-sm'">
            <!-- <div   style="font-weight: 600; font-size: large;" class="sticky-headers-1"  >
              Service Provider: {{ report.sp_name}}
              
            </div> -->
            
             <div v-for="(report1,j) in report.locations" :key="j"  >
              <div  style="font-weight: 600; font-size: larger;"  class="sticky-headers-1">
              Location: {{ report1.location_name}}
            </div>
              
            
            <div   v-for="(report2,k) in report1.services" :key="k"  >
              <div style="font-weight: 600;"  class="sticky-headers-2">
              Service : {{ report2.service_name}}
            </div>
              
            
             
          
            <v-table :columns="report2.service_type=='fdle'  ?columns:columnsWithNoOri" class="sticktyyyyy" style="widows: 100%;">
            <tr v-for="(item, index) in report2.data" :key="index">
              <td >
                {{ ++index }}
              </td>
              
              <td><div>{{ item.applicant }}</div><div style="font-size:x-small;">{{item.tcn}}</div></td>
              <td v-if="report2.service_type=='fdle'">{{ item.ori }}</td>
              
              <td>
                {{item.service_status}}
          
              </td>
             
              <td v-if="item.enroll_date != null">
                {{ dateFormat(item.enroll_date) }}
              </td>
              <td v-if="item.enroll_date == null">-</td>
              <td class="text-right" v-if="item.cost">
                $ {{ item.cost | toFixedTwoDigit }}
              </td>
              <td class="text-right" v-else>$ 0.00</td>
              <td class="text-right" v-if="item.markup && currentUser.u.is_service_provider">
                $ {{ item.markup | toFixedTwoDigit }}
              </td>
              <td class="text-right" v-if="currentUser.u.is_service_provider && !item.markup">$ 0.00</td>
              <td class="text-right" v-if="item.tax && currentUser.u.is_service_provider">
                $ {{ item.tax | toFixedTwoDigit }}
              </td>
              <td class="text-right" v-if="!item.tax && currentUser.u.is_service_provider">$ 0.00</td>
              <td class="text-right" v-if="item.commission">
                $ {{ item.commission | toFixedTwoDigit }}
              </td>
              <td class="text-right" v-else>$ 0.00</td>
      
              <td class="text-right" v-if="item.selling_price">
                $ {{ item.selling_price | toFixedTwoDigit }}
              </td>
              <td class="text-right" v-else>$ 0.00</td>
            </tr>
            <tr>
          
              <td
              
                :colspan="report2.service_type=='fdle' ?5:4"
              >
                <b>Sub Total</b>
              </td>
              <td class="text-right">
                <b>
                  $
                  {{ report2.data.reduce((acc, obj) => acc + Number(obj.cost), 0) | toFixedTwoDigit }}</b
                >
              </td>
              <td class="text-right" v-if="currentUser.u.is_service_provider">
                <b>
                  $
                  {{ report2.data.reduce((acc, obj) => acc + Number(obj.markup), 0) | toFixedTwoDigit }}</b
                >
              </td>
              <td class="text-right" v-if="currentUser.u.is_service_provider">
                <b>
                  $
                  {{ report2.data.reduce((acc, obj) => acc + Number(obj.tax), 0) | toFixedTwoDigit }}</b
                >
              </td>
              <td class="text-right" >
                <b>
                  $
                  {{ report2.data.reduce((acc, obj) => acc + Number(obj.commission), 0) | toFixedTwoDigit }}</b
                >
              </td>
              <td class="text-right" style="width: 6%">
                <b>
                  $
                  {{ report2.data.reduce((acc, obj) => acc + Number(obj.selling_price), 0) | toFixedTwoDigit }}</b
                >
              </td>
            </tr>
          </v-table>
        </div>
      </div> 
        </div>  
          
       
 
        </div>
      </div>
      </div>
      <div class="row" v-if="reports.length < 1 && !getLoadingData && !load">
        <div class="col-md-12 text-center">
          <h4>no report(s) found</h4>
        </div>
      </div>
      <div class="row" v-if="load" style="opacity: 0.8">
        <div class="col-md-12 text-center">
          <h4>Please select the criteria</h4>
        </div>
      </div>

      <div class="row move-below" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
    </div>
    <div class="sticyyyyyyy-btm  p-0" v-if="reports.length>0 && !getLoadingData">
      <div class="px-5">
      <v-table   >
          <tr>
          
          <td
          
            :colspan="5"
          >
            <b>Grand Total</b>
          </td>
          <td :style="currentUser.u.is_service_provider?'width:47.5%':'width:63%'"></td>
      
          <td class="text-right">
            <b>
                $ {{ reports.reduce((acc, reports1) => acc + reports1.locations.reduce((sum, location) => sum + location.services.reduce((cost, serviceData) => cost + serviceData.data.reduce((subtotal, entry) => subtotal + parseFloat(entry.cost), 0), 0), 0), 0).toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}) }}</b>
           </td>
          <td class="text-right" v-if="currentUser.u.is_service_provider">
            <b>
                $ {{ reports.reduce((acc, reports1) => acc + reports1.locations.reduce((sum, location) => sum + location.services.reduce((cost, serviceData) => cost + serviceData.data.reduce((subtotal, entry) => subtotal + parseFloat(entry.markup), 0), 0), 0), 0).toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}) }}</b>
           </td>
          <td class="text-right" v-if="currentUser.u.is_service_provider">
            <b>
                $ {{ reports.reduce((acc, reports1) => acc + reports1.locations.reduce((sum, location) => sum + location.services.reduce((cost, serviceData) => cost + serviceData.data.reduce((subtotal, entry) => subtotal + parseFloat(entry.tax), 0), 0), 0), 0).toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}) }}</b>
           </td>
          <td class="text-right" >
            <b>
                $ {{ reports.reduce((acc, reports1) => acc + reports1.locations.reduce((sum, location) => sum + location.services.reduce((cost, serviceData) => cost + serviceData.data.reduce((subtotal, entry) => subtotal + parseFloat(entry.commission), 0), 0), 0), 0).toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}) }}</b>
           </td>
          <td class="text-right">
            <b>
                $ {{ reports.reduce((acc, reports1) => acc + reports1.locations.reduce((sum, location) => sum + location.services.reduce((cost, serviceData) => cost + serviceData.data.reduce((subtotal, entry) => subtotal + parseFloat(entry.selling_price), 0), 0), 0), 0).toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}) }}</b>
           </td>
          <!-- <td class="text-right">
            <b>
             $ {{ reports.reduce((acc, obj) => acc + obj.data.reduce((sum, obj1) => sum + Number(obj1.), 0), 0) | toFixedTwoDigitreports.reduce((acc, reports1) => acc + reports1.locations.reduce((sum, location) => sum + location.services.reduce((cost, serviceData) => cost + serviceData.data.reduce((subtotal, entry) => subtotal + parseFloat(entry.markup), 0), 0), 0), 0) | toFixedTwoDigit }}</b>
           </td>
          <td class="text-right">
            <b>
              $ {{reports.reduce((acc, reports1) => acc + reports1.locations.reduce((sum, location) => sum + location.services.reduce((cost, serviceData) => cost + serviceData.data.reduce((subtotal, entry) => subtotal + parseFloat(entry.tax), 0), 0), 0), 0) | toFixedTwoDigit }}</b>
           </td>
          <td class="text-right">
            <b>
                $ {{ reports.reduce((acc, obj) => acc + obj.data.reduce((sum, obj1) => sum + Number(obj1.selling_price), 0), 0) | toFixedTwoDigit }}</b
            >
           </td> -->
      
        </tr> 
      </v-table>
    </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  FETCH_CLIENTS,
  FETCH_SERVICES,
  FETCH_LOCATION_WISE_REPORT
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import vTable from "@/includes/table/Datatable";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import AdvSearch from "@/includes/search/AdvSearch";
// import * as PDF417 from "pdf417-generator";
export default {
  components: {
    vTable,
    vDataLoader,
    AdvSearch
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "getClients",
      "getServices",
      "getCompanies",
      "getSearchDate"
    ]),
    grand_total: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += element.amount;
        });
      }
      return total;
    },
    total_commission: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += element.commission;
        });
      }
      return total;
    },
    total_rate: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += element.rate;
        });
      }
      return total;
    },
    total_tax: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += element.tax;
        });
      }
      return total;
    }
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    }
  },
  data() {
    return {
      isTableVisible: true,
      search_text:"",
      search: {
        service: "all",
        company: "all",
        date_from: "",
        date_to: "",
        service_status: "200",
        payment_status: "all"
      },

      getLoadingData: false,
      columns: [
      {
          name: "S.No",
          width: "5%",
          label: "S.No",
          class: "sticky-header"
        },
        {
          name: "Applicant Name",
          width: "15%",
          label: "Applicant Name",
          class: "sticky-header"
        },
        {
          name: "ORI",
          width: "5%",
          label: "ORI",
          class: "sticky-header"
        },
        {
          name: "Service Status",
          width: "5%",
          label: "Service Status",
          class: "sticky-header"
        },

        {
          name: "Enrollment Date",
          width: "5%",
          label: "Enrollment Date",
          class: "sticky-header"
        },
        {
          name: "Cost",
          width: "5%",
          label: "Cost",
          class: "text-right sticky-header"
        },
        {
          name: "Markup",
          width: "5%",
          label: "Markup",
          class: "text-right sticky-header"
        },
        {
          name: "tax",
          width: "5%",
          label: "Tax",
          class: "text-right sticky-header"
        },
        {
          name: "commission",
          width: "5%",
          label: "Commission",
          class: "text-right sticky-header"
        },
        {
          name: "Selling Price",
          width: "5%",
          label: "Selling Price",
          class: "text-right sticky-header"
        }
      ],
      columnsWithNoOri: [
      {
          name: "S.No",
          width: "5%",
          label: "S.No",
          class: "sticky-header"
        },
        {
          name: "Applicant Name",
          width: "18%",
          label: "Applicant Name",
          class: "sticky-header"
        },
        {
          name: "Service Status",
          width: "10%",
          label: "Service Status",
          class: "sticky-header"
        },

        {
          name: "Enrollment Date",
          width: "5%",
          label: "Enrollment Date",
          class: "sticky-header"
        },
        {
          name: "Cost",
          width: "5%",
          label: "Cost",
          class: "text-right sticky-header"
        },
        {
          name: "Markup",
          width: "5%",
          label: "Markup",
          class: "text-right sticky-header"
        },
        {
          name: "tax",
          width: "5%",
          label: "Tax",
          class: "text-right sticky-header"
        },
        {
          name: "commission",
          width: "2%",
          label: "Commission",
          class: "text-right sticky-header"
        },
        {
          name: "Selling Price",
          width: "5%",
          label: "Selling Price",
          class: "text-right sticky-header"
        }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Report",
          route: ""
        },
        {
          id: 2,
          title: "Service Report",
          route: ""
        }
      ],
      reports: [],
      header: [],
      load: true,
      excelreports: [],
      titleworksheet: "",
      json_fields: {
             // "Location	": "location_name",
             "": "applicant",
        " ":"ori",
        "  ": "service_status",
        "   ": "enroll_date",
        // Service: "service",
        // "Payment Status": "payment_status",
        // "Scheduled Date": "schedule_date",
        "    ": "cost",
        "     ":"markup",
        "      ": "tax",
        "       ":"commission",
        "        ": "selling_price"
      }
    };
  },

  mounted() {
    this.$store.dispatch(FETCH_CLIENTS);
    let serviceOutlet = this.currentUser.u.is_client ? "self" : "all";
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_SERVICES, serviceOutlet);
    const observer = new IntersectionObserver(entries => {
        this.isTableVisible = entries[0].isIntersecting;
      });
      observer.observe(this.$el);

    if(this.currentUser.u.is_client){
    
     this.columns.splice(-3, 1);
     this.columns.splice(-3, 1);
      this.columnsWithNoOri.splice(-3, 1);
      this.columnsWithNoOri.splice(-3, 1);
    }
  },

  methods: {
    searchByTCN(){
      if(this.search_text!=''){
      this.searchRequestHandler({search_text:this.search_text})
      }
      else{
        this.$toastr.e("Please enter tcn or name to search");
      }
    },
    advSearch(value) {
      this.search_text="";
      
      let { header, search, footer } = value;
      this.header = header;
      console.log(this.header)
      this.search = { ...search };
      search.search_text=''
      this.tablereset();
      this.titleworksheet = search.date_from + " - " + search.date_to;
      search.payment_status='all'
      this.searchRequestHandler(search)

    
    },
    tablereset() {
      if (this.search.location != "all" && this.search.service == "all") {
        // this.columns = [
        // {
        //   name: "S.No",
        //   width: "",
        //   label: "S.No",
        //   class: ""
        // },
        //   {
        //     name: "Applicant Name",
        //     width: "",
        //     label: "Applicant Name",
        //     class: ""
        //   },
        //   {
        //   name: "ORI",
        //   width: "",
        //   label: "ORI",
        //   class: ""
        // },
        //   {
        //     name: "Service Name",
        //     width: "",
        //     label: "Service ",
        //     class: ""
        //   },
        //   {
        //     name: "Service Status",
        //     width: "",
        //     label: "Service Status",
        //     class: ""
        //   },

        //   {
        //     name: "Payment Status",
        //     width: "",
        //     label: "Payment Status",
        //     class: ""
        //   },
        //   {
        //     name: "Scheduled Date",
        //     width: "",
        //     label: "Scheduled Date",
        //     class: ""
        //   },
        //   {
        //   name: "Cost",
        //   width: "",
        //   label: "Cost",
        //   class: "text-right"
        // },
        // {
        //   name: "Markup",
        //   width: "",
        //   label: "Markup",
        //   class: "text-right"
        // },
        // {
        //   name: "tax",
        //   width: "",
        //   label: "Tax",
        //   class: "text-right"
        // },
        // {
        //   name: "commission",
        //   width: "",
        //   label: "Commission",
        //   class: "text-right"
        // },
        // {
        //   name: "Selling Price",
        //   width: "",
        //   label: "Selling Price",
        //   class: "text-right"
        // }
        // ];
        // this.json_fields = {
        //   "Applicant Name": "applicant",
        //   Service: "service",
        //   "Service Status": "service_status",
        //   "Payment Status": "payment_status",
        //   "Scheduled Date": "schedule_date",
        //   Rate: "rate",
        //   Tax: "tax",
        //   Commission: "commission",
        //   Amount: "amount"
        // };
      } else if (
        this.search.location == "all" &&
        this.search.service != "all"
      ) {
        // this.columns = [
        // {
        //   name: "S.No",
        //   width: "",
        //   label: "S.No",
        //   class: ""
        // },
        //   {
        //     name: "location",
        //     width: "",
        //     label: "Location",
        //     class: ""
        //   },
        //   {
        //     name: "Applicant Name",
        //     width: "",
        //     label: "Applicant Name",
        //     class: ""
        //   },
        //   {
        //   name: "ORI",
        //   width: "",
        //   label: "ORI",
        //   class: ""
        // },

        //   {
        //     name: "Service Status",
        //     width: "",
        //     label: "Service Status",
        //     class: ""
        //   },

        //   {
        //     name: "Payment Status",
        //     width: "",
        //     label: "Payment Status",
        //     class: ""
        //   },
        //   {
        //     name: "Scheduled Date",
        //     width: "",
        //     label: "Scheduled Date",
        //     class: ""
        //   },
        //   {
        //   name: "Cost",
        //   width: "",
        //   label: "Cost",
        //   class: "text-right"
        // },
        // {
        //   name: "Markup",
        //   width: "",
        //   label: "Markup",
        //   class: "text-right"
        // },
        // {
        //   name: "tax",
        //   width: "",
        //   label: "Tax",
        //   class: "text-right"
        // },
        // {
        //   name: "commission",
        //   width: "",
        //   label: "Commission",
        //   class: "text-right"
        // },
        // {
        //   name: "Selling Price",
        //   width: "",
        //   label: "Selling Price",
        //   class: "text-right"
        // }
        // ];
        // this.json_fields = {
        //   "Location	": "location_name",
        //   "Applicant Name": "applicant",
        //   "Service Status": "service_status",
        //   "Payment Status": "payment_status",
        //   "Scheduled Date": "schedule_date",
        //   Rate: "rate",
        //   Tax: "tax",
        //   Commission: "commission",
        //   Amount: "amount"
        // };
      } else if (
        this.search.location != "all" &&
        this.search.service != "all"
      ) {
        // this.columns = [
        // {
        //   name: "S.No",
        //   width: "",
        //   label: "S.No",
        //   class: ""
        // },
        //   {
        //     name: "Applicant Name",
        //     width: "",
        //     label: "Applicant Name",
        //     class: ""
        //   },
        //   {
        //   name: "ORI",
        //   width: "",
        //   label: "ORI",
        //   class: ""
        // },

        //   {
        //     name: "Service Status",
        //     width: "",
        //     label: "Service Status",
        //     class: ""
        //   },

        //   {
        //     name: "Payment Status",
        //     width: "",
        //     label: "Payment Status",
        //     class: ""
        //   },
        //   {
        //     name: "Scheduled Date",
        //     width: "",
        //     label: "Scheduled Date",
        //     class: ""
        //   },
        //   {
        //   name: "Cost",
        //   width: "",
        //   label: "Cost",
        //   class: "text-right"
        // },
        // {
        //   name: "Markup",
        //   width: "",
        //   label: "Markup",
        //   class: "text-right"
        // },
        // {
        //   name: "tax",
        //   width: "",
        //   label: "Tax",
        //   class: "text-right"
        // },
        // {
        //   name: "commission",
        //   width: "",
        //   label: "Commission",
        //   class: "text-right"
        // },
        // {
        //   name: "Selling Price",
        //   width: "",
        //   label: "Selling Price",
        //   class: "text-right"
        // }
        // ];
        // this.json_fields = {
        //   "Applicant Name": "applicant",
        //   "Service Status": "service_status",
        //   "Payment Status": "payment_status",
        //   "Scheduled Date": "schedule_date",
        //   Rate: "rate",
        //   Tax: "tax",
        //   Commission: "commission",
        //   Amount: "amount"
        // };
      } else {
        // this.json_fields = {
        //   "Location	": "location_name",
        //   "Applicant Name": "applicant",
        //   Service: "service",
        //   "Service Status": "service_status",
        //   "Payment Status": "payment_status",
        //   "Scheduled Date": "schedule_date",
        //   Rate: "rate",
        //   Tax: "tax",
        //   Commission: "commission",
        //   Amount: "amount"
        // };
        // this.columns = [
        // {
        //   name: "S.No",
        //   width: "",
        //   label: "S.No",
        //   class: ""
        // },
        //   {
        //     name: "location",
        //     width: "",
        //     label: "Location",
        //     class: ""
        //   },
        //   {
        //     name: "Applicant Name",
        //     width: "",
        //     label: "Applicant Name",
        //     class: ""
        //   },
        //   {
        //   name: "ORI",
        //   width: "",
        //   label: "ORI",
        //   class: ""
        // },
        //   {
        //     name: "Service Name",
        //     width: "",
        //     label: "Service ",
        //     class: ""
        //   },
        //   {
        //     name: "Service Status",
        //     width: "",
        //     label: "Service Status",
        //     class: ""
        //   },

        //   {
        //     name: "Payment Status",
        //     width: "",
        //     label: "Payment Status",
        //     class: ""
        //   },
        //   {
        //     name: "Scheduled Date",
        //     width: "",
        //     label: "Scheduled Date",
        //     class: ""
        //   },
        //   {
        //   name: "Cost",
        //   width: "",
        //   label: "Cost",
        //   class: "text-right"
        // },
        // {
        //   name: "Markup",
        //   width: "",
        //   label: "Markup",
        //   class: "text-right"
        // },
        // {
        //   name: "tax",
        //   width: "",
        //   label: "Tax",
        //   class: "text-right"
        // },
        // {
        //   name: "commission",
        //   width: "",
        //   label: "Commission",
        //   class: "text-right"
        // },
        // {
        //   name: "Selling Price",
        //   width: "",
        //   label: "Selling Price",
        //   class: "text-right"
        // }
        // ];
      }
    },
    searchRequestHandler(search){
      this.getLoadingData = true;
      this.load = false;
      this.$store
        .dispatch(FETCH_LOCATION_WISE_REPORT, search)
        .then(res => {
          this.getLoadingData = false;
          this.isTableVisible=true;
          this.reports = res.data;
          let tempArray=[]
               
          res.data.forEach((element)=>{
            element.locations.forEach((element1)=>{
             
              element1.services.forEach((element2)=>{
            let totalCost = element2.data.reduce((acc, obj) => acc + Number(obj.cost), 0);
            let totalMarkup = element2.data.reduce((acc, obj) => acc + Number(obj.markup), 0);
            let totalTax = element2.data.reduce((acc, obj) => acc + Number(obj.tax), 0);
            let totalCommission=element2.data.reduce((acc, obj) => acc + Number(obj.commission), 0)
            let totalSellingPrice = element2.data.reduce((acc, obj) => acc + Number(obj.selling_price), 0);
            const nameOfServiceAndProvider=`<td colspan=7>${element.sp_name+ '\n' + element1.location_name +"\n"+ element2.service_name}</th>`
            tempArray.push(
              {
                applicant: nameOfServiceAndProvider,
              ori: "",
              Status: "",
              service_status: "",
              enroll_date: "",
              cost: '',
              markup: '',
              tax: '',
              commission:"",
              selling_price: ''
              },
              {        // "Location	": "location_name",
        "applicant":"Applicant Name",
        "tcn":"TCN",
        "ori":"ORI",
        "service_status": "Status",
        "enroll_date": "Enrolled Date",
        // Service: "service",
        // "Payment Status": "payment_status",
        // "Scheduled Date": "schedule_date",
        cost: "Cost",
        markup:"Markup",
        tax: "Tax",
        "selling_price": "Selling Price"},
              ...element2.data.map(element3 => {
    if (element2.service_type === 'fdle') {
      element3.enroll_date=new Date (element3.enroll_date).toLocaleDateString()
      if ((element3.service_status === '200' || element3.service_status === '500') && element3.service_type !== 'fingerprintcard') {
        element3.service_status = "Ready To Transmit";
    } else if ((element3.service_status === '200' || element3.service_status === '500') && element3.service_type === 'fingerprintcard') {
        element3.service_status = "Final";
    } else if ((element3.service_status === '300' || element3.service_status === '600') && element3.service_type === 'fingerprintcard') {
        element3.service_status = "Final";
    } else if ((element3.service_status === '300' || element3.service_status === '600') && element3.service_type !== 'fingerprintcard') {
        element3.service_status = "Transmitted";
    } else if (element3.service_status === '100' || element3.service_status === '400') {
        element3.service_status = "Draft";
    } else {
        element3.service_status = "-";
    }

        return element3;
    } else {
      element3.enroll_date=new Date (element3.enroll_date).toLocaleDateString()
      if ((element3.service_status === '200' || element3.service_status === '500') && element3.service_type !== 'fingerprintcard') {
        element3.service_status = "Ready To Transmit";
    } else if ((element3.service_status === '200' || element3.service_status === '500') && element3.service_type === 'fingerprintcard') {
        element3.service_status = "Final";
    } else if ((element3.service_status === '300' || element3.service_status === '600') && element3.service_type === 'fingerprintcard') {
        element3.service_status = "Final";
    } else if ((element3.service_status === '300' || element3.service_status === '600') && element3.service_type !== 'fingerprintcard') {
        element3.service_status = "Transmitted";
    } else if (element3.service_status === '100' || element3.service_status === '400') {
        element3.service_status = "Draft";
    } else {
        element3.service_status = "-";
    }

        element1.ori = '';
        return element3;
    }
            }),
            {
              applicant: "Sub Total",
              ori: "",
              Status: "",
              service_status: "",
              enroll_date: "",
              cost: totalCost,
              markup: totalMarkup,
              tax: totalTax,
              commission:totalCommission,
              selling_price: totalSellingPrice
            },
            {
              applicant: "",
              ori: "",
              Status: "",
              service_status: "",
              enroll_date: "",
              cost: '',
              markup: '',
              tax: '',
              commission:"",
              selling_price: ''
            }
          

        // totals: {
        //     cost: totalCost,
        //     markup: totalMarkup,
        //     // Other totals
        // }

        );
      })
        })
       this.excelreports = [...tempArray];

          })
          let data = [...res.data];
          let gCost =data.reduce((acc, reports1) => acc + reports1.locations.reduce((sum, location) => sum + location.services.reduce((cost, serviceData) => cost + serviceData.data.reduce((subtotal, entry) => subtotal + parseFloat(entry.cost), 0), 0), 0), 0).toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})
          let gMarkup =data.reduce((acc, reports1) => acc + reports1.locations.reduce((sum, location) => sum + location.services.reduce((cost, serviceData) => cost + serviceData.data.reduce((subtotal, entry) => subtotal + parseFloat(entry.markup), 0), 0), 0), 0).toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})
          let gTax = data.reduce((acc, reports1) => acc + reports1.locations.reduce((sum, location) => sum + location.services.reduce((cost, serviceData) => cost + serviceData.data.reduce((subtotal, entry) => subtotal + parseFloat(entry.tax), 0), 0), 0), 0).toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})
          let gComission = data.reduce((acc, reports1) => acc + reports1.locations.reduce((sum, location) => sum + location.services.reduce((cost, serviceData) => cost + serviceData.data.reduce((subtotal, entry) => subtotal + parseFloat(entry.commission), 0), 0), 0), 0).toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})
          let gsellingPrice = data.reduce((acc, reports1) => acc + reports1.locations.reduce((sum, location) => sum + location.services.reduce((cost, serviceData) => cost + serviceData.data.reduce((subtotal, entry) => subtotal + parseFloat(entry.selling_price), 0), 0), 0), 0).toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})
       
if (data.length != 0) {
            let gtotal = {
              
              applicant: "Grand Total",
              ori: "",
              Status: "",
              service_status: "",
              enroll_date: "",
              cost: gCost,
              markup: gMarkup,
              tax: gTax,
              commission:gComission,
              selling_price: gsellingPrice
            };
            this.excelreports.push(gtotal);
          }
          let foo = {
            applicant: "",
              ori: "",
              Status: "",
              service_status: "",
              enroll_date: "",
              selling_price: footer[3],
            tax: footer[2],
            markup: footer[1],
            cost: footer[0]
          };
          this.excelreports.push(foo);
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    },
    refreshData() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
    },

    statusColor(status) {
      if (status == "P") {
        return "warning";
      }
      if (status == "C") {
        return "success";
      }
      if (status == "U") {
        return "info";
      }
      if (status == "Cancel") {
        return "danger";
      }
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      //   this.appData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      //   this.appData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      //   this.appData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      //   this.appData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      //   this.appData();
    }
  }
};
</script>
